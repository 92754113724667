import {CouponType} from './coupon';
import React, {Component} from 'react';

interface CouponCardProps {
    couponType: CouponType
}

export class CouponCard extends Component<CouponCardProps> {
    render() {
        return (
            <div className="Card Coupon">
                <div className="Coupon-Content">
                    {
                        this.props.couponType.deactivated &&
                        <span className="Deactivated">Temporär deaktiviert</span>
                    }
                    <div className="Coupon-Shop">
                        {this.props.couponType.shop}
                    </div>
                    <div className="Coupon-Title">{this.props.couponType.title}</div>
                </div>
                <div className="Coupon-Arrow">›</div>
            </div>
        );
    }
}
