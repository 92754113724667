import {Coupon, CouponType} from './coupon';
import React, {Component, RefObject} from 'react';
import {Location} from 'history';
import {BrowserRouter, Route, match, Redirect} from 'react-router-dom';
import {CouponForm} from './CouponForm';

export interface CouponViewProps {
    couponTypes: CouponType[];
    coupons: Coupon[];
    redirected?: boolean;
    match: match<any>;
    location: Location<{ redirectedFromForm: true } | NodeJS.PoorMansUnknown>;
    newCoupon: (coupon: Coupon) => void;
    removeCoupon: (coupon: Coupon) => void;
    history?: any;
}

export class CouponView extends Component<CouponViewProps> {
    codeRef: RefObject<HTMLSpanElement> = React.createRef();

    copyToClipboard() {
        const range = document.createRange();
        range.selectNodeContents(this.codeRef.current as HTMLSpanElement);
        const selection = window.getSelection();
        if (selection) {
            selection.addRange(range);
            document.execCommand('copy');
        }
    }

    CouponInformation(coupon: Coupon | undefined) {
        if (coupon) {
            return (
                <div>
                    {this.CouponAlreadyRequestedMessage()}
                    <h3>Dein Code</h3>
                    <div className="Coupon-Code">
                        <span ref={this.codeRef}>{coupon.code}</span>
                        {
                            document.queryCommandSupported('copy') &&
                            <button className="Compact" onClick={() => this.copyToClipboard()}>Copy</button>
                        }
                    </div>
                    <p>Der Code wurde auch an deine E-Mail-Adresse({coupon.email}) gesendet.</p>
                    {/*<b>Coupon funktioniert nicht?</b>
                    <button className="Compact" onClick={() => this.props.removeCoupon(coupon)}>Neuen Coupon anfordern</button>*/}
                </div>
            )
        } else {
            return <Redirect to={`${this.props.match.url}/request`}/>
        }
    }

    CouponAlreadyRequestedMessage() {
        if (this.props.location.state?.hasOwnProperty('redirectedFromForm')) {
            return (
                <div className="Alert Info">
                    Du hast diesen Gutschein bereits angefordert.
                </div>
            )
        }
    }

    CouponFormWrapper(couponType: CouponType, coupon: Coupon | undefined) {
        if (!coupon) {
            return (
                <CouponForm couponType={couponType} newCoupon={this.props.newCoupon}/>
            )
        } else {
            return <Redirect to={{pathname: this.props.match.url, state: {redirectedFromForm: true}}}/>
        }
    }

    CouponBaseCard(couponType: CouponType, coupon: Coupon | undefined) {
        return (
            <div className="Card">
                <div className="Coupon-Content">
                    {
                        couponType.deactivated &&
                        <span className="Deactivated">Temporär deaktiviert</span>
                    }
                    <div className="Coupon-Shop">{couponType.shop}</div>
                    <div className="Coupon-Title">{couponType.title}</div>
                </div>
                <hr/>
                <BrowserRouter>
                    <Route exact path={this.props.match.url}>
                        {this.CouponInformation(coupon)}
                    </Route>
                    <Route path={`${this.props.match.url}/request`}>
                        {this.CouponFormWrapper(couponType, coupon)}
                    </Route>
                </BrowserRouter>
            </div>
        );
    }

    NotFound() {
        return (
            <p>Der angefragte Coupon konnte nicht gefunden werden</p>
        )
    }

    render() {
        const id = this.props.match.params.id;
        const couponType = this.props.couponTypes.find(couponType => couponType.id === id);
        const coupon = this.props.coupons.find(coupon => coupon.couponTypeId === id);
        return (
            <main>
                <h1>Dein Coupon</h1>
                {couponType ? this.CouponBaseCard(couponType, coupon) : this.NotFound()}
            </main>
        );
    }
}
