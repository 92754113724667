import {Coupon, CouponType} from './coupon';
import React, {Component} from 'react';
import {CouponCard} from './CouponType';
import {Link} from 'react-router-dom';

interface CouponListProps {
    couponTypes: CouponType[];
    coupons: Coupon[];
}

export class CouponList extends Component<CouponListProps> {
    AvailableCoupons() {
        const coupons = this.props.couponTypes.filter(couponType =>
            couponType.available && this.props.coupons.every(coupon => coupon.couponTypeId !== couponType.id)
        ).map(couponType => (
            <Link key={couponType.id} to={`/coupon/${couponType.id}/request`}>
                <CouponCard couponType={couponType}/>
            </Link>
        ));
        return [
                <h1 key="Title">Aktuelle Gutscheine</h1>,
            ...coupons,
                !coupons.length && <p key="NoCoupons">Aktuell sind keine Gutscheine verfügbar - schau später nochmal vorbei!</p>
        ]
    }

    RequestedCoupons() {
        const coupons = this.props.couponTypes.filter(couponType =>
            this.props.coupons.some(coupon => coupon.couponTypeId === couponType.id)
        ).map(couponType => (
            <Link key={couponType.id} to={`/coupon/${couponType.id}`}>
                <CouponCard couponType={couponType}/>
            </Link>
        ));
        if (coupons.length) {
            return (
                <div>
                    <h1>Angeforderte Gutscheine</h1>
                    {coupons}
                </div>
            )
        }
    }

    render() {
        return (
            <main>
                {this.AvailableCoupons()}
                {this.RequestedCoupons()}
            </main>
        );
    }
}
