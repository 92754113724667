import {Coupon, CouponType} from './coupon';
import React, {ChangeEvent, Component, FormEvent} from 'react';

interface CouponFormProps {
    couponType: CouponType;
    newCoupon: (coupon: Coupon) => void;
}

interface CouponFormState {
    email: string;
    requestData?: RequestInit;
    request?: Promise<any>;
    error?: string;
}

export class CouponForm extends Component<CouponFormProps, CouponFormState> {
    abortController = new AbortController();
    requestData: RequestInit | undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            email: '',
        }
    }

    componentWillUnmount(): void {
        this.abortController.abort();
    }

    generateUuid(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    handleChange(event: ChangeEvent<HTMLInputElement>) {
        this.setState({...this.state, email: event.target.value})
    }

    async sendRequest(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        if (!this.state.request) {
            this.requestData = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    couponTypeId: this.props.couponType.id,
                    email: this.state.email,
                    requestId: this.generateUuid()
                }),
                signal: this.abortController.signal
            };
            const request = fetch(
                '/api/CouponRequest',
                this.requestData
            );
            this.setState({
                ...this.state,
                request
            });
            try {
                const result = await request;
                const responseBody: Coupon | { error: string } = JSON.parse(await result.text());
                if (!result.ok) {
                    this.setState({
                        ...this.state,
                        request: undefined,
                        error: (responseBody as { error: string }).error
                    });
                } else {
                    this.props.newCoupon(responseBody as Coupon);
                    this.requestData = undefined;
                }
            } catch (e) {
                this.setState({...this.state, request: undefined, error: 'Unknown Error'});
            }
        }
    }

    OutOfStock() {
        return <p>Es sind leider keine Gutscheine mehr da - sorry!</p>
    }

    Deactivated() {
        return (
            <p>
                Die Verteilung der Gutscheine wurde pausiert.<br/>
                Dieses Fenster aktualisiert sich automatisch, sobald die Gutscheine wieder freigegeben werden.
            </p>
        )
    }

    CouponForm() {
        return ([
            this.state.request && <div className="Spinner" key="Spinner">
                <div className="Spinner-Inner">
                    <div className="Spinner-Bounce1"/>
                    <div className="Spinner-Bounce2"/>
                </div>
            </div>,
            <p key="Mail-Info">Wir benötigen deine E-Mail-Adresse, um dir deinen Gutscheincode zuzusenden.</p>,
            <form key="Form" onSubmit={(event) => this.sendRequest(event)}>
                <label>
                    E-Mail
                    <input type="text" name="email" value={this.state.email}
                           onChange={(event) => this.handleChange(event)}/>
                </label>
                <button>Absenden</button>
            </form>,
            this.state.error &&
            <div className="Alert Error" key="Alert">
                {this.state.error}
            </div>,
        ])
    }


    render() {
        return (
            <div className="Form-Section">
                <h2>Code anfordern</h2>
                {
                    this.state.request ? this.CouponForm() : (
                        !this.props.couponType.available ? this.OutOfStock() : (
                            this.props.couponType.deactivated ? this.Deactivated() : this.CouponForm())
                    )
                }
            </div>
        );
    }
}
