import React, {Component} from 'react';
import {
    Switch,
    Route,
    Link
} from 'react-router-dom';
import './App.css';
import {CouponList} from './CouponList';
import {CouponView,} from './CouponView';
import {Coupon, CouponType} from './coupon';
import {Location} from 'history';
import 'firebase/firestore';
import * as firebase from 'firebase/app';
import {Transition} from 'react-spring/renderprops-universal';
import {animated} from 'react-spring';

interface AppState {
    couponTypes: CouponType[] | undefined,
    coupons: Coupon[];
    couponCollection: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
}

export interface AppProps {
    location: Location<NodeJS.PoorMansUnknown>
}

export class App extends Component<AppProps, AppState> {
    listener = (event: StorageEvent) => {
        if (event.newValue !== event.oldValue) {
            this.setState({
                ...this.state,
                coupons: this.getCoupons()
            })
        }
    };
    couponTypesSubscription: (() => void) | undefined;

    constructor(props: any) {
        super(props);
        this.state = {
            couponTypes: undefined,
            couponCollection: firebase.firestore().collection('CouponType'),
            coupons: this.getCoupons()
        };
    }

    addCouponTypesSubscription() {
        this.couponTypesSubscription = this.state.couponCollection.onSnapshot(snapshot => {
            this.setState({
                couponTypes: snapshot.docs.map((doc: firebase.firestore.DocumentData) =>
                    ({id: doc.id, ...doc.data()} as CouponType))
            });
        });
    }

    getCoupons(): Coupon[] {
        const items = localStorage.getItem('coupon');
        if (!items) {
            return [];
        } else {
            return JSON.parse(items);
        }
    }

    addCoupon(coupon: Coupon) {
        this.setState({
            ...this.state,
            coupons: [...this.state.coupons, coupon]
        })
    }

    removeCoupon(coupon: Coupon) {
        const couponsCopy = [...this.state.coupons];
        couponsCopy.splice(this.state.coupons.indexOf(coupon), 1);
        this.setState({
            ...this.state,
            coupons: couponsCopy
        })
    }

    updateCoupons(): void {
        const json = JSON.stringify(this.state.coupons);
        if (json !== localStorage.getItem('coupon')) {
            localStorage.setItem('coupon', json);
        }
    }

    async componentDidMount(): Promise<void> {
        window.addEventListener('storage', this.listener);
        this.addCouponTypesSubscription();
    }

    componentWillUnmount(): void {
        window.removeEventListener('storage', this.listener);
        this.couponTypesSubscription?.();
    }

    LoadingIndicator() {
        return <p>Loading data...</p>;
    }

    RoutedContent() {
        return <Transition
            items={this.props.location}
            keys={this.props.location.pathname}
            from={{transform: 'translateY(20px)', opacity: 0}}
            enter={{position: 'static', transform: 'translateY(0px)', opacity: 1}}
            leave={{position: 'absolute', transform: 'translateY(0px)', opacity: 0, width: '100%'}}>
            {(loc, state) => style => (
                <animated.div key={this.props.location.pathname.split('/')[1]} style={style}>
                    <Switch location={loc}>
                        <Route exact path="/">
                            <CouponList couponTypes={this.state.couponTypes || []}
                                        coupons={this.state.coupons}/>
                        </Route>
                        <Route path="/coupon/:id"
                               render={(props) => <CouponView {...props}
                                                              coupons={this.state.coupons}
                                                              couponTypes={this.state.couponTypes as CouponType[]}
                                                              newCoupon={(coupon) => this.addCoupon(coupon)}
                                                              removeCoupon={(coupon) => this.removeCoupon(coupon)}
                               />}/>
                    </Switch>
                </animated.div>
            )}
        </Transition>
    }

    render() {
        this.updateCoupons();
        return (
            <div className="App">
                <header className="App-header">
                    <Link to="/">
                        <div className="Title">Moko</div>
                    </Link>
                </header>
                <div className="Animated">
                    {this.state.couponTypes ? this.RoutedContent() : this.LoadingIndicator()}
                </div>
                <div className="Filler"/>
                <footer>
                    © 2020 Moko
                </footer>
            </div>
        );
    }
};
