import React from 'react';
import ReactDOM, {render} from 'react-dom';
import './index.css';
import {App} from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase/app';
import 'firebase/performance';
import 'firebase/analytics';
import {BrowserRouter, useLocation} from 'react-router-dom';

const firebaseConfig = {
    apiKey: "AIzaSyCU60C1ZuVpl3Mgfo52ikn58E0kVzV4oow",
    authDomain: "coupondispenser.firebaseapp.com",
    databaseURL: "https://coupondispenser.firebaseio.com",
    projectId: "coupondispenser",
    storageBucket: "coupondispenser.appspot.com",
    messagingSenderId: "662991064151",
    appId: "1:662991064151:web:0c5ec78b324375b791a712",
    measurementId: "G-4KJ5R7CGDJ"
};
firebase.initializeApp(firebaseConfig);
firebase.performance();
firebase.analytics();

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <AppWrap/>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

function AppWrap() {
    const location = useLocation();
    return <App location={location}/>
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
